<ion-header [translucent]="true">
  <ion-toolbar color="light">
    <ion-title>{{ title() }}</ion-title>
    <ion-text slot="end" class="ion-padding-end">{{ emailUser() }}</ion-text>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" color="light">
  <ion-header collapse="condense">
    <ion-toolbar color="light">
      <ion-title size="large">{{ title() }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-list inset="true">
    <ion-list-header><ion-label>トランザクション</ion-label></ion-list-header>
    <ion-item-group>
      @for (t of slipType | keyvalue: unsorted; track t; let i = $index) {
        <ion-item button="true" (click)="doTransaction(t.key)">
          <ion-icon slot="start" name="server-outline" class="menu-icon color-primary"></ion-icon>
          <ion-label>{{ t.value }}</ion-label>
        </ion-item>
      }
      <ion-item button="true" (click)="doRestore()">
        <ion-icon slot="start" name="clipboard-outline" class="menu-icon color-success"></ion-icon>
        <ion-label>復元</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <ion-list inset="true">
    <ion-list-header>
      <ion-label>タスク</ion-label>
    </ion-list-header>
    <ion-item-group>
      <ion-item button="true" lines="none" routerLink="task/choice">
        <ion-icon slot="start" name="checkbox-outline" class="menu-icon color-danger"></ion-icon>
        <ion-label>Dタスク</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <ion-list inset="true">
    <ion-list-header>
      <ion-label>伝票検索</ion-label>
    </ion-list-header>
    <ion-item-group>
      <ion-item button="true" lines="none" routerLink="search-slip">
        <ion-icon slot="start" name="search-outline" class="menu-icon color-success"></ion-icon>
        <ion-label>伝票検索</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  @if (!platform.is('hybrid')) {
    <ion-list inset="true">
      <ion-list-header><ion-label>管理処理</ion-label></ion-list-header>
      <ion-item-group>
        <ion-item routerLink="/control/csv-update">
          <ion-icon slot="start" name="cloud-upload-outline" class="menu-icon color-primary"></ion-icon>
          <ion-label>CSVインポート</ion-label>
        </ion-item>
        <ion-item routerLink="/control/close">
          <ion-icon slot="start" name="lock-closed-outline" class="menu-icon color-primary"></ion-icon>
          <ion-label>請求締め処理</ion-label>
        </ion-item>
      </ion-item-group>
    </ion-list>
  }

  <ion-list inset="true">
    <ion-list-header>
      <ion-label>マスタ</ion-label>
    </ion-list-header>
    <ion-item-group>
      <ion-item routerLink="/resources/shop">
        <ion-icon slot="start" name="home-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>店舗マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/sku">
        <ion-icon slot="start" name="file-tray-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>商品マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/company">
        <ion-icon slot="start" name="water-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>企業・企業管理マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/subject">
        <ion-icon slot="start" name="flag-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>件名マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/ae">
        <ion-icon slot="start" name="person-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>担当者マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/price/company">
        <ion-icon slot="start" name="pricetag-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>企業別単価マスタ</ion-label>
      </ion-item>
      <ion-item routerLink="/resources/price/subject">
        <ion-icon slot="start" name="pricetag-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>件名単価マスタ</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <ion-list inset="true">
    <ion-list-header><ion-label>アカウント</ion-label></ion-list-header>
    <ion-item-group>
      <ion-item button="true" lines="none" (click)="doSignout()">
        <ion-icon slot="start" name="power-outline" class="menu-icon color-warning"></ion-icon>
        <ion-label>サインアウト</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <ion-list inset="true">
    <ion-list-header><ion-label>設定</ion-label></ion-list-header>
    <ion-item-group>
      <ion-item button="true" (click)="checkPosition()">
        <ion-label>現在地を表示</ion-label>
      </ion-item>
      <ion-item button="true" (click)="doDataDeleted()">
        <ion-label>デバイス内データを削除</ion-label>
      </ion-item>
      <ion-item>
        <ion-label>バージョン</ion-label>
        <ion-text slot="end">{{ version() }}</ion-text>
      </ion-item>
    </ion-item-group>
  </ion-list>
</ion-content>
