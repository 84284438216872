import { Component, inject } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAppLaunchConstant } from '@rdlabo/odss-mobile-api/src/app.api';
import { HelperService } from '../../../shared/src/lib/services/helper.service';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  private readonly http = inject(HttpClient);
  private readonly helper = inject(HelperService);

  constructor() {
    this.initializeApp();
  }

  async initializeApp() {
    FirebaseAnalytics.initializeFirebase(environment.firebase).then();
    this.http.get<IAppLaunchConstant>(environment.api()).subscribe((data) => this.helper.setConstant(data));
  }
}
